<template>
  <div class="relative w-full min-h-screen">
    <div class="absolute inset-0 z-0 bg-black" />
    <div
      class="absolute inset-0 z-0"
      :style="{
        backgroundImage: `url(${require('@/assets/background.jpeg')})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        filter: 'brightness(0.3)',
      }"
    />

    <div class="absolute top-4 right-4 z-20">
      <div class="relative">
        <button
          @click="isDropdownOpen = !isDropdownOpen"
          class="flex items-center space-x-2 bg-white/10 backdrop-blur-sm px-4 py-2 rounded-lg text-white hover:bg-white/20"
        >
          <Globe class="h-5 w-5" />
          <span>{{
            getCurrentLanguage === "en" ? "English" : "Português"
          }}</span>
          <ChevronDown class="h-4 w-4" />
        </button>

        <div
          v-if="isDropdownOpen"
          class="absolute right-0 mt-2 w-48 rounded-lg bg-white shadow-lg"
        >
          <button
            @click="handleLanguageChange('en')"
            class="block w-full text-left px-4 py-2 hover:bg-gray-100 rounded-t-lg"
          >
            English
          </button>
          <button
            @click="handleLanguageChange('pt')"
            class="block w-full text-left px-4 py-2 hover:bg-gray-100 rounded-b-lg"
          >
            Português
          </button>
        </div>
      </div>
    </div>

    <div
      class="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 md:py-32"
    >
      <div class="text-center">
        <h1 class="text-4xl md:text-6xl font-bold text-white mb-6">
          {{ t.title }}
        </h1>

        <p class="text-xl md:text-2xl text-gray-200 mb-8 max-w-3xl mx-auto">
          {{ t.description }}
        </p>

        <div
          class="flex flex-col sm:flex-row items-center justify-center gap-4 mb-12"
        >
          <div class="flex items-center space-x-2 text-white">
            <MapPin class="h-5 w-5" />
            <span>{{ t.location }}</span>
          </div>
          <div class="flex items-center space-x-2 text-white">
            <Calendar class="h-5 w-5" />
            <span>{{ t.opening }}</span>
          </div>
        </div>

        <div class="max-w-3xl mx-auto space-y-12 mb-12">
          <!-- Sobre -->
          <div class="text-center space-y-4">
            <h2 class="text-2xl md:text-3xl font-bold text-white">
              {{ t.aboutTitle }}
            </h2>
            <p class="text-lg text-gray-200 leading-relaxed">
              {{ t.aboutDescription }}
            </p>
          </div>

          <!-- Missão -->
          <div class="text-center space-y-4">
            <h2 class="text-2xl md:text-3xl font-bold text-white">
              {{ t.missionTitle }}
            </h2>
            <p class="text-lg text-gray-200 leading-relaxed">
              {{ t.missionDescription }}
            </p>
          </div>

          <!-- Objetivos -->
          <!-- <div class="text-center space-y-4">
            <h2 class="text-2xl md:text-3xl font-bold text-white">
              {{ t.objectivesTitle }}
            </h2>
            <ul class="grid md:grid-cols-2 gap-4 text-left">
              <li
                v-for="(objective, index) in t.objectives"
                :key="index"
                class="flex items-start space-x-2 text-gray-200"
              >
                <div class="mt-1.5">
                  <div class="w-2 h-2 rounded-full bg-blue-500"></div>
                </div>
                <span class="text-lg">{{ objective }}</span>
              </li>
            </ul>
          </div> -->
        </div>

        <!-- Botões de Lista de Espera e Apoio -->
        <div class="flex flex-col sm:flex-row justify-center gap-4 mb-12">
          <button
            @click="trackWaitListClick"
            class="px-8 py-3 border-2 border-white text-white rounded-lg hover:bg-white hover:text-black transition-colors font-semibold"
          >
            {{ t.waitlist }}
          </button>
          <button
            @click="trackSupportClick"
            class="px-8 py-3 border-2 border-white text-white rounded-lg hover:bg-white hover:text-black transition-colors font-semibold"
          >
            {{ t.support }}
          </button>
        </div>

        <div class="flex justify-center space-x-6">
          <a
            href="https://museu.acessa.ae"
            class="text-white hover:text-gray-300"
            :aria-label="t.twitter"
          >
            <Twitter class="h-6 w-6" />
          </a>
          <a
            href="https://museu.acessa.ae"
            class="text-white hover:text-gray-300"
            :aria-label="t.instagram"
          >
            <Instagram class="h-6 w-6" />
          </a>
        </div>
      </div>
    </div>

    <div
      class="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-black to-transparent z-10"
    />

    <!-- Waitlist Modal -->
    <div
      v-if="isModalOpen"
      class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <div class="bg-white rounded-lg p-8 max-w-md w-full mx-4">
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-2xl font-bold">{{ t.waitlist }}</h2>
          <button
            @click="isModalOpen = false"
            class="text-gray-500 hover:text-gray-700"
          >
            <X class="h-6 w-6" />
          </button>
        </div>
        <form class="space-y-4" @submit.prevent="submitWaitlistForm">
          <div>
            <label class="block text-sm font-medium text-gray-700 mb-1">
              {{ t.name }}
            </label>
            <div class="relative">
              <User
                class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5"
              />
              <input
                v-model="formData.name"
                type="text"
                required
                class="pl-10 w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                :placeholder="t.name"
              />
            </div>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-700 mb-1">
              {{ t.email }}
            </label>
            <div class="relative">
              <Mail
                class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5"
              />
              <input
                v-model="formData.email"
                type="email"
                required
                class="pl-10 w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                :placeholder="t.email"
              />
            </div>
          </div>
          <div class="flex items-center">
            <input
              v-model="formData.newsletter"
              type="checkbox"
              id="newsletter"
              class="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label for="newsletter" class="ml-2 block text-sm text-gray-700">
              {{ t.newsletter }}
            </label>
          </div>
          <button
            type="submit"
            :disabled="isSubmitting"
            class="w-full bg-blue-600 text-white rounded-lg px-4 py-2 hover:bg-blue-700 transition-colors disabled:bg-blue-400"
          >
            {{ isSubmitting ? t.sending : t.submit }}
          </button>
        </form>
      </div>
    </div>

    <!-- Support Modal -->
    <div
      v-if="isSupportModalOpen"
      class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <div class="bg-white rounded-lg p-8 max-w-md w-full mx-4">
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-2xl font-bold">{{ t.support }}</h2>
          <button
            @click="isSupportModalOpen = false"
            class="text-gray-500 hover:text-gray-700"
          >
            <X class="h-6 w-6" />
          </button>
        </div>
        <form class="space-y-4" @submit.prevent="submitSupportForm">
          <div class="flex gap-4">
            <label class="flex items-center">
              <input
                v-model="supportFormData.type"
                type="radio"
                value="individual"
                class="mr-2"
              />
              {{ t.individual }}
            </label>
            <label class="flex items-center">
              <input
                v-model="supportFormData.type"
                type="radio"
                value="company"
                class="mr-2"
              />
              {{ t.company }}
            </label>
          </div>

          <div>
            <label class="block text-sm font-medium text-gray-700 mb-1">
              {{ t.contributionType }}
            </label>
            <select
              v-model="supportFormData.contributionType"
              required
              class="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option :value="t.donation">{{ t.donation }}</option>
              <option :value="t.volunteer">{{ t.volunteer }}</option>
              <option :value="t.partnership">{{ t.partnership }}</option>
            </select>
          </div>

          <div>
            <label class="block text-sm font-medium text-gray-700 mb-1">
              {{ t.name }}
            </label>
            <div class="relative">
              <User
                class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5"
              />
              <input
                v-model="supportFormData.name"
                type="text"
                required
                class="pl-10 w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                :placeholder="t.name"
              />
            </div>
          </div>

          <div v-if="supportFormData.type === 'company'">
            <label class="block text-sm font-medium text-gray-700 mb-1">
              {{ t.companyName }}
            </label>
            <div class="relative">
              <Building
                class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5"
              />
              <input
                v-model="supportFormData.companyName"
                type="text"
                required
                class="pl-10 w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                :placeholder="t.companyName"
              />
            </div>
          </div>

          <div>
            <label class="block text-sm font-medium text-gray-700 mb-1">
              {{ t.email }}
            </label>
            <div class="relative">
              <Mail
                class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5"
              />
              <input
                v-model="supportFormData.email"
                type="email"
                required
                class="pl-10 w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                :placeholder="t.email"
              />
            </div>
          </div>

          <div>
            <label class="block text-sm font-medium text-gray-700 mb-1">
              {{ t.phone }}
            </label>
            <div class="relative">
              <Phone
                class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5"
              />
              <input
                v-model="supportFormData.phone"
                type="tel"
                required
                class="pl-10 w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="+55 (11) 99999-9999"
              />
            </div>
          </div>

          <div>
            <label class="block text-sm font-medium text-gray-700 mb-1">
              {{ t.supportDescription }}
            </label>
            <div class="relative">
              <FileText class="absolute left-3 top-3 text-gray-400 h-5 w-5" />
              <textarea
                v-model="supportFormData.description"
                required
                class="pl-10 w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                :placeholder="t.supportDescription"
                rows="2"
              />
            </div>
          </div>

          <button
            type="submit"
            :disabled="isSubmitting"
            class="w-full bg-blue-600 text-white rounded-lg px-4 py-2 hover:bg-blue-700 transition-colors disabled:bg-blue-400"
          >
            {{ isSubmitting ? t.sending : t.submit }}
          </button>
        </form>
      </div>
    </div>

    <!-- Toast Messages -->
    <div
      v-if="toast.show"
      class="fixed bottom-4 right-4 z-50 px-6 py-3 rounded-lg shadow-lg"
      :class="{
        'bg-green-500': toast.type === 'success',
        'bg-red-500': toast.type === 'error',
      }"
    >
      <p class="text-white">{{ toast.message }}</p>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapGetters, mapActions } from "vuex";
import EmailService from "@/services/email";
import {
  Mail,
  MapPin,
  Calendar,
  Twitter,
  Instagram,
  Globe,
  ChevronDown,
  X,
  User,
  Building,
  Phone,
  FileText,
} from "lucide-vue-next";

export default {
  name: "HeroSection",
  components: {
    Mail,
    MapPin,
    Calendar,
    Twitter,
    Instagram,
    Globe,
    ChevronDown,
    X,
    User,
    Building,
    Phone,
    FileText,
  },
  setup() {
    const isDropdownOpen = ref(false);
    const isModalOpen = ref(false);
    const isSupportModalOpen = ref(false);
    const isSubmitting = ref(false);

    const toast = ref({
      show: false,
      message: "",
      type: "success",
    });

    const formData = ref({
      name: "",
      email: "",
      newsletter: false,
    });

    const supportFormData = ref({
      type: "individual",
      contributionType: "donation",
      name: "",
      companyName: "",
      email: "",
      phone: "",
      description: "",
    });

    const showToast = (message, type = "success") => {
      toast.value = {
        show: true,
        message,
        type,
      };
      setTimeout(() => {
        toast.value.show = false;
      }, 3000);
    };

    return {
      isDropdownOpen,
      isModalOpen,
      isSupportModalOpen,
      isSubmitting,
      formData,
      supportFormData,
      toast,
      showToast,
    };
  },

  computed: {
    ...mapGetters(["getCurrentLanguage", "getTranslations"]),
    t() {
      return this.getTranslations;
    },
  },

  methods: {
    ...mapActions(["changeLanguage"]),

    handleLanguageChange(lang) {
      this.changeLanguage(lang);
      this.isDropdownOpen = false;
    },
    trackWaitListClick() {
      this.$gtag.event("waitlist_click", {
        event_category: "waitlist",
        event_label: "waitlist_button",
      });

      this.isModalOpen = true;
    },
    trackSupportClick() {
      this.$gtag.event("support_click", {
        event_category: "support",
        event_label: "support_button",
      });

      this.isSupportModalOpen = true;
    },
    async submitWaitlistForm() {
      this.isSubmitting = true;

      this.$gtag.event("waitlist_submit", {
        event_category: "waitlist",
        event_label: "waitlist_form",
      });

      try {
        await EmailService.sendWaitlistNotification(this.formData);
        this.showToast(this.t.successMessage, "success");
        this.isModalOpen = false;
        this.formData = {
          name: "",
          email: "",
          newsletter: false,
        };
      } catch (error) {
        console.error("Erro ao enviar formulário:", error);
        this.showToast(this.t.errorMessage, "error");
      } finally {
        this.isSubmitting = false;
      }
    },

    async submitSupportForm() {
      this.isSubmitting = true;

      this.$gtag.event("support_submit", {
        event_category: "support",
        event_label: "support_form",
      });

      try {
        await EmailService.sendSupportNotification(this.supportFormData);
        this.showToast(this.t.successMessage, "success");
        this.isSupportModalOpen = false;
        this.supportFormData = {
          type: "individual",
          contributionType: "donation",
          name: "",
          companyName: "",
          email: "",
          phone: "",
          description: "",
        };
      } catch (error) {
        console.error("Erro ao enviar formulário:", error);
        this.showToast(this.t.errorMessage, "error");
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>
